import React from 'react';
import styled from 'styled-components';

const Container = styled.div`

    display: flex;
    justify-content: center;
`;

const Item = styled.div(props => `

    width: ${props.width !== undefined ? props.width : `auto`};
`);

function Center(props){

    return (

        <Container>

            <Item width={props.width}>{props.children}</Item>

        </Container>
    );
}

export default Center;

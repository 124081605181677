import {DateTime} from 'luxon';
import {isNumeric, size} from 'library/helpers';

function Time(){

    // Translate format to Luxon format

    this.format = function(format){

        if(format === undefined || format === 'dd.mm.yyyy'){

            format = 'dd.MM.yyyy';
        }

        else if(format === 'yy.mm.dddd hh:mm'){

            format = 'dd.MM.yyyy HH:mm';
        }

        else if(format === 'dd.mm.yyyy hh:mm:ss'){

            format = 'dd.MM.yyyy HH:mm:ss';
        }

        else if(format === 'yyyy'){

            format = 'yyyy';
        }

        else if(format === 'ww'){

            // Weeks as 01, 02, 03...

            format = 'WW';
        }

        else if(format === 'unix' || format === 'timestamp'){

            format = 'X';
        }

        else if(format === 'hh:mm:ss'){

            format = 'HH:mm:ss';
        }

        else {

            throw new Error('Invalid format');
        }

        return format;
    }

    // Set time

    this.set = function(v, format){

        // Set default time

        if(v === undefined){

            this.luxon = DateTime.local();
        }

        // Map supported format to Luxon formats

        else {

            // Set default format

            format = this.format(format);

            if(format === 'X'){

                this.luxon = DateTime.fromSeconds(v);
            }

            else {

                this.luxon = DateTime.fromFormat(v, format);
            }
        }

        return this;
    }

    // Get weeks

    this.getWeeks = function(v){

        let direction = 'plus';

        if(v < 0){

            direction = 'minus';

            v = Math.abs(v);
        }

        const weeks = [];

        for(let i = 0; i < v; i++){

            let week = {};

            if(direction === 'plus'){

                const date = this.luxon.plus({weeks: i});

                week.number = date.toFormat('WW');
                week.year = date.weekYear;
            }

            else if(direction === 'minus'){

                const date = this.luxon.minus({weeks: i});

                week.number = date.toFormat('WW');
                week.year = date.weekYear;
            }

            weeks.push(week);
        }

        return weeks;
    }

    this.getDate = function(modifier, format){

        // Set default day as current

        if(modifier === undefined || size(modifier) === 0){

            modifier = {day: 0};
        }

        // Set default format

        format = this.format(format);

        // Handle value as object

        if(typeof modifier === 'object'){

            if(modifier.day !== undefined && isNumeric(modifier.day) === true){

                // Add days 

                if(modifier.day >= 0){

                    return this.luxon.plus({day: modifier.day}).toFormat(format);
                }

                // Reduce days

                else if(modifier.day < 0){

                    return this.luxon.minus({day: Math.abs(modifier.day)}).toFormat(format);
                }
            }

            if(modifier.month !== undefined && isNumeric(modifier.month) === true){

                // Add months

                if(modifier.month >= 0){

                    return this.luxon.plus({month: modifier.month}).toFormat(format);
                }

                // Reduce months

                else if(modifier.month < 0){

                    return this.luxon.minus({month: Math.abs(modifier.month)}).toFormat(format);
                }
            }

            if(modifier.week !== undefined && isNumeric(modifier.week) === true){

                // Add weeks

                if(modifier.week >= 0){

                    return this.luxon.plus({month: modifier.month}).toFormat(format);
                }

                // Reduce weeks

                else if(modifier.week < 0){

                    return this.luxon.minus({week: Math.abs(modifier.week)}).toFormat(format);
                }
            }
        }

        // Handle value as string

        else if(typeof modifier === 'string'){

            // First day of month

            if(modifier === 'first day of month'){

                return this.luxon.startOf('month').toFormat(format);
            }

            // Last day of month

            else if(modifier === 'last day of month'){

                return this.luxon.endOf('month').toFormat(format);
            }
        }
    }

    this.isValid = function(){

        if(this.luxon.isValid === false){

            return false;
        }

        return true;
    }
}

export default Time;

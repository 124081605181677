// Replace string or regular expression with JSX

import React from 'react';

export default (original, callback) => {

    let jsx = <React.Fragment>{original}</React.Fragment>;

    function map(search, replace){

        if(typeof replace === 'function'){

            const tasks = match(search, replace, original);

            tasks.forEach(v => {

                jsx = iterate(v.search, v.replace, jsx);
            });
        }

        else {

            jsx = iterate(search, replace, jsx);
        }
    }

    function match(regexp, callback, subject){

        const tasks = [];

        /*

        TODO:

        Matching with /<div>(.*?)</div>/g does not work with nested divs like: <div>First<div>Second</div></div>

        */

        const matches = subject.match(new RegExp(regexp, 'g'));

        if(matches !== null){

            matches.forEach(v => {

                const content = v.match(new RegExp(regexp));
                const params = [];

                content.forEach((param, k) => {

                    if(k > 0){

                        params.push(param);
                    }
                });

                const called = callback(...params);

                tasks.push({search: content[0], replace: called});
            });
        }

        return tasks;
    }

    function iterate(search, replace, target){

        if(React.Children.count(target.props.children) === 0){

            return target;
        }

        let children = React.Children.map(target.props.children, subject => {

            if(React.isValidElement(subject) === false){

                return convert(search, replace, subject);
            }

            else {

                return iterate(search, replace, subject);
            }
        });

        if(children.length === 1){

            children = children[0];
        }

        return React.cloneElement(target, [], children);
    }

    function convert(search, replace, subject){

        const segments = subject.split(search);

        if(segments.length === 1){

            return subject;
        }

        else {

            const replaced = [];

            for(let i = 0; i < segments.length; i++){

                if(segments[i] !== ''){

                    replaced.push(segments[i]);
                }

                if(segments.length > i + 1){

                    replaced.push(replace);
                }
            }

            if(replaced.length === 1){

                return replaced[0];
            }

            return replaced;
        }
    }

    callback(map);

    return jsx;
}

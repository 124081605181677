import request from 'library/request';
import {useContext} from 'react';
import AppContext from 'library/AppContext';

function useRequest(){

    const context = useContext(AppContext);

    async function setRequest(options){

        return await request(options).then(

            result => {

                return result;
            },

            error => {

                context.loading.setLoading(false);

                if(error.forbidden === true){

                    context.openForbiddenModal();
                }

                else if(error.network === true){

                    if(options.error !== undefined){

                        context.openNetworkErrorModal(options.error); // Legacy support
                    }

                    else if(options.retry !== undefined){

                        context.openNetworkErrorModal(options.retry);
                    }
                }

                return Promise.reject(error);
            }
        );
    }

    return setRequest;
}

export default useRequest;

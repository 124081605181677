import {isNumeric} from 'library/helpers';

function storeReducer(stores, actions){

    // Merge mulptile actions and run them once to prevent unnecessary rendering

    if(Array.isArray(actions) === false){

        const tmp = [];

        tmp.push(actions);

        actions = tmp;
    }

    actions.forEach(action => {

        setStore(action);
    });

    function setStore(action){

        if(action.type === 'clear' && action.store === undefined){

            for(let key in stores){

                stores[key].clear();
            }
        }

        else {

            // Create new store if one is not already defined

            if(stores[action.store] === undefined){

                stores[action.store] = new Map();
            }

            const store = stores[action.store];

            const data = action.result !== undefined ? action.result.data : undefined;

            switch(action.type){

                case 'delete':

                    if(data.id !== undefined){

                        store.delete(parseInt(data.id));
                    }
                
                break;

                case 'add':
                case 'update':

                    if(data !== undefined){

                        //  Add multiple items

                        if(Array.isArray(data)){

                            // Replace old data with new items

                            if(action.type === 'add'){

                                store.clear();
                            }

                            data.forEach(v => {

                                if(v.id !== undefined){

                                    if(isNumeric(v.id)){

                                        store.set(parseInt(v.id), v); // Numeric ID
                                    }

                                    else {

                                        store.set(v.id, v); // String ID
                                    }
                                }
                            });
                        }

                        // Add or update existing single item

                        else if(data.id !== undefined){

                            if(isNumeric(data.id)){ 

                                store.set(parseInt(data.id), data); // Numeric ID
                            }

                            else { 

                                store.set(data.id, data); // String ID
                            }
                        }
                    }

                break;

                case 'clear':

                    store.clear(store);

                break;

                default:

                    console.warn('Unknown action type:', action.type);

                    return stores;
            }
        }
    }

    return Object.assign({}, stores);
}

export default storeReducer;

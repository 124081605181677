import {Preloading, Loading, Alert, Confirm} from 'library/components';

const config = {

    defaultTheme: 'common',

    redirect: {

        forbidden: path => {

            if(path.match(/\/admin\//i) !== null){

                return '/admin/login.html';
            }

            return '/';
        }
    },

    components: {

        loading: Loading,
        preloading: Preloading,
        alert: Alert,
        confirm: Confirm
    },

    endpoints: {

        privileges: {

           endpoint: '/api/common/privileges/',
           method: 'get'
        },

        refreshAccessToken: {

            endpoint: '/api/common/refresh_access_token/',
            method: 'get'
        }
    }
};

export default config;

import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button.attrs(props => ({

    width: props.styled.width || '100%',
    marginBottom: props.styled.marginBottom || 0
}))`

    ${props => `

        padding: ${props.theme.padding.smaller};
        background: linear-gradient(${props.theme.backgroundColor.sub.light}, ${props.theme.backgroundColor.sub.normal});
        border: 1px solid ${props.theme.backgroundColor.sub.normal};
        color: ${props.theme.color.sub.normal};
        border-radius: ${props.theme.borderRadius.smaller};
        height: ${props.theme.height.normal};
        outline: 0;
        width: ${props.width};
        cursor: pointer;
        white-space: nowrap;
        margin-bottom: ${props.marginBottom};

        &:hover {

            background: linear-gradient(${props.theme.backgroundColor.positive.light}, ${props.theme.backgroundColor.positive.normal});
            border: 1px solid ${props.theme.backgroundColor.positive.normal};
        }
    `}
`;

function Button(props){

    const type = props.type !== undefined ? props.type : props.onClick !== undefined ? 'button' : 'submit';

    return (

        <StyledButton styled={props.styled || {}} onClick={props.onClick} type={type}>{props.children}</StyledButton>
    );
}

export default Button;

import config from 'config';

async function request(options){

    // Set default mode

    if(options.mode === undefined){

       options.mode = 'FormData';
    }

    const hosts = [];

    JSON.parse(process.env.HOSTS).forEach(v => {

        hosts.push({host: v, online: true});
    });

    function getHost(){

        for(let i = 0; hosts[i] !== undefined; i++){

            // Set current host to offline

            if(host !== undefined && hosts[i].host === host){

                hosts[i].online = false;
            }

            // Return first host which is online

            if(hosts[i].online === true){

                return hosts[i].host;
            }
        }

        // All hosts are offline

        return false;
    }

    let host = options.host;

    if(host === undefined){

        host = getHost();
    }

    let tryRenewAccessToken = true; // Try renew access token only once

    async function renewAccessToken(){

        tryRenewAccessToken = false;

        const options = config.endpoints.refreshAccessToken;
        const url = host + options.endpoint;

        const params = {

            method: options.method,
            headers: {}
        }

        let refresh_token = localStorage.getItem('refresh_token');

        if(refresh_token === null){

            return false;
        }

        params.headers['Authorization'] = `Bearer ${refresh_token}`;

        let result;

        try {

            const response = await fetch(url, params);

            result = await response.json();

            console.log('Result:', result);
        }

        catch(error){

            return false;
        }

        if(typeof result !== 'object'){

            return false;
        }

        if(typeof result.data !== 'object'){

            return false;
        }

        if(result.data.access_token === undefined || result.data.refresh_token === undefined){

            return false;
        }

        localStorage.setItem('access_token', result.data.access_token);
        localStorage.setItem('refresh_token', result.data.refresh_token);

        return true;
    }

    async function make(){

        // Send data as FormData or JSON

        let data;
        
        if(options.mode === 'FormData'){

            data = new FormData();
        }

        else if(options.mode === 'JSON'){

            data = {};
        }

        else {

            throw new Error('Unknown mode');
        }

        if(options.data !== undefined && options.data instanceof Map === true){

            options.data.forEach((v, k) => {

                if(options.mode === 'FormData'){
                
                    data.append(k, v);
                }

                else if(options.mode === 'JSON'){
                
                    data[k] = v;
                }
            });
        }

        const headers = {};

        let access_token = localStorage.getItem('access_token');

        if(access_token !== null){

            headers['Authorization'] = `Bearer ${access_token}`;
        }

        // Set custom headers via options

        if(options.headers !== undefined){

            for(let header in options.headers){

                headers[header] = options.headers[header];
            }
        }

        const url = host + options.endpoint;

        const params = {

            method: options.method,
            headers: headers
        };

        if(params.method.toUpperCase() !== 'GET'){

            params.body = data;
        }

        let result = false;

        try {

            const response = await fetch(url, params);

            if(response.status === 403){

                // Try renew access token

                if(tryRenewAccessToken === true && await renewAccessToken() === true){

                    // Make request again with new access token

                    return make();
                }

                else {

                    // Access token is expired and cannot be renewed

                    return Promise.reject({error: response.statusText, forbidden: true});
                }
            }

            result = await response.json();
        }

        // Fetch will throw error only if the is no connection

        catch(error){

            // Try to change host (old code when axios was used this should propably be inside of try block)

            host = getHost();

            if(host !== false){
            
                return make();
            }

            return Promise.reject({error: error.message, network: true, timeout: true});
        }

        return result;
    }

    return make();
}

export default request;

import React from 'react';
import styled from 'styled-components';

const Container = styled.div(() => `

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`);

const Left = styled.div(props => `

    width: 100%;

    @media (min-width: ${props.theme.minWidth.tablet}){

        width: ${props.styled.width};
        padding-right: ${props.theme.padding.smaller};
    }
`);

const Right = styled.div(props => `

    width: 100%;

    @media (min-width: ${props.theme.minWidth.tablet}){

        width: ${props.styled.width};
        padding-left: ${props.theme.padding.smaller};
    }
`);

function Columns(props){    

    return <Container>{props.children}</Container>;
}

Columns.left = function LeftColumn(props){

    return <Left styled={props.styled || {}}>{props.children}</Left>;
}

Columns.left.defaultProps = {

    styled: {

        width: '50%'
    }
}

Columns.right = function RightColumn(props){

    return <Right styled={props.styled || {}}>{props.children}</Right>;
}

Columns.right.defaultProps = {

    styled: {

        width: '50%'
    }
}

export default Columns;

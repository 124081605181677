export default function size(v){

    if(typeof v !== 'object' || Array.isArray(v) === true || v === null){

        return false;
    }

    else {

        // Regular object property count

        let size = 0;

        for(let k in v){

            if(v[k] !== undefined){

                size++;
            }
        }

        return size; 
    }
}

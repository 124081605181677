import {useRef, useState} from 'react';

function useForm(init){

    const [, setState] = useState(0);

    if(typeof init === 'object' && init instanceof Map === false){

        const map  = new Map();

        for(let k in init){

            map.set(k, init[k]); 
        }

        init = map;
    }

    const ref = useRef({

        data: init || new Map(),

        set: (input, update) => {

            if(input.value === undefined){

                if(ref.current.data.get(input.name) !== undefined){

                    ref.current.data.delete(input.name);
                }
            }

            else {

                ref.current.data.set(input.name, input.value);
            }

            update = update === undefined ? true : update;

            if(update === true){
            
                setState((prev) => prev + 1);
            }
        },

        getData: key => {

            const value = ref.current.data.get(key);

            if(value === undefined){

                return '';
            }

            return value;
        },

        get: (key, value) => {

            if(key === undefined){

                return ref.current.data;
            }

            // If key is array like example[] return Map of matched fields

            const regex = new RegExp(/\[\]/);

            if(key.match(regex) !== null){

                const [name] = key.split('[]')
                const collection = new Map();

                ref.current.data.forEach((v, k) => {

                    const regex = RegExp(name + '[[a-zA-Z0-9_-]+]');

                    if(k.match(regex) !== null){

                        collection.set(k, ref.current.data.get(k));
                    }
                });

                return collection;
            }

            if(ref.current.data.get(key) === undefined){

                ref.current.set({name: key, value: value}, false);

                if(value === undefined){
                    
                    // Cannot return undefined or otherwise will trigger warning  "A component is changing an uncontrolled input of type text to be controlled"

                    return '';
                }

                return value;
            }

            return ref.current.getData(key);
        },

        clear: () => {

            ref.current.data.clear();

            setState((prev) => prev + 1);
        }
    });

    return [ref.current.get, ref.current.set, ref.current.clear];
}

export default useForm;

import React, {useRef} from 'react';
import styled from 'styled-components';
import {isNumeric} from 'library/helpers';

const Label = styled.div(props => `

    padding-bottom: ${props.theme.padding.normal};
`);

const Files = styled.div(props => `

    padding: ${props.theme.padding.normal};
    background: #fff;
    border-radius: ${props.theme.borderRadius.smaller} ${props.theme.borderRadius.smaller} 0 0;
    border-top: 1px solid ${props.theme.borderColor.main.normal};
    border-right: 1px solid ${props.theme.borderColor.main.normal};
    border-left: 1px solid ${props.theme.borderColor.main.normal};

    & > div {

        margin-bottom: ${props.theme.margin.normal};
    }

    & > div:last-of-type {

        margin-bottom: 0;
    }
`);

const File = styled.div(() => `

    display: flex;
`);

const Name = styled.div(props => `

    display: flex;
    flex-grow: 1;
    align-items: center;
    background: ${props.theme.backgroundColor.grays.light};
    border-top: 1px solid ${props.theme.borderColor.main.normal};
    border-bottom: 1px solid ${props.theme.borderColor.main.normal};
    border-left: 1px solid ${props.theme.borderColor.main.normal};
    padding: 0 ${props.theme.padding.smaller} 0 ${props.theme.padding.smaller};
    border-radius: ${props.theme.borderRadius.smaller} 0 0 ${props.theme.borderRadius.smaller};
`);

const Remove = styled.label(props => `

    display: block;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(${props.theme.backgroundColor.sub.light}, ${props.theme.backgroundColor.sub.normal});
    border: 1px solid ${props.theme.backgroundColor.sub.normal};
    color: ${props.theme.color.sub.normal};
    padding: ${props.theme.padding.smaller};
    white-space: nowrap;
    border-radius: 0 ${props.theme.borderRadius.smaller} ${props.theme.borderRadius.smaller} 0;

    &:hover {
    
        background: linear-gradient(${props.theme.backgroundColor.positive.light}, ${props.theme.backgroundColor.positive.normal});
        border: 1px solid ${props.theme.backgroundColor.positive.normal};
    }
`);

const Input = styled.input(() => `

    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`);

const Add = styled.label(props => `

    display: block;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(${props.theme.backgroundColor.sub.light}, ${props.theme.backgroundColor.sub.normal});
    border: 1px solid ${props.theme.backgroundColor.sub.normal};
    color: ${props.theme.color.sub.normal};
    padding: ${props.theme.padding.smaller};
    white-space: nowrap;
    border-radius: 0 0 ${props.theme.borderRadius.smaller} ${props.theme.borderRadius.smaller};
    margin-bottom: ${props.theme.margin.normal};

    &:hover {
    
        background: linear-gradient(${props.theme.backgroundColor.positive.light}, ${props.theme.backgroundColor.positive.normal});
        border: 1px solid ${props.theme.backgroundColor.positive.normal};
    }
`);

const Icon = styled.i(props => `

    padding-right: ${props.theme.padding.smaller};
`);

export default props => {

    const ref = useRef(0);

    const files = [];

    props.value.forEach((v, k) => {

        files.push({name: k, value: v});
    });

    function add(e){

        for(let k in e.target.files){

            if(isNumeric(k) === true){

                const [name] = props.name.split('[]');

                props.onChange({name: name + '[' + ref.current + ']', value: e.target.files[k]});

                ref.current++;
            }
        }

        e.target.value = '';
    }

    function remove(k){

        props.onChange({name: k, value: undefined});
    }

    return (

        <React.Fragment>

            {props.label !== undefined && <Label>{props.label}:</Label>}

            <Files>

                {files.length === 0 ? props.placeholder :

                    <React.Fragment>

                        {files.map((file) =>

                            <File key={file.name}>

                                <Name>{file.value.name}</Name>
                                <Remove onClick={() => remove(file.name)} styled={{width: 'auto'}}>{props.remove}</Remove>

                            </File>
                        )}

                    </React.Fragment>
                }

            </Files>

            <Input type="file" name={props.name} id={props.name} onChange={add} multiple={true} />

            <Add htmlFor={props.name}>

                <Icon className="fa fa-upload" aria-hidden="true" />
                {props.tooltip}

            </Add>

        </React.Fragment>
    );
}

import React from 'react';
import ReactDOM from 'react-dom';
import App, {route, group} from 'library/App';

route('/@common/views/Home'); // Frontpage
route('/documents/usage-terms.html@common/views/UsageTerms.js'); // Usage terms
route('/orders/:uuid/payment.html@common/views/Payment.js', 'orders@getSingle'); // Payment
route('/orders/:uuid/summary.html@common/views/Summary.js', 'orders@getSingle'); // Summary
route('/admin/login.html@admin/views/Login'); // Login

group('admin', () => {

    route('/admin/home.html@admin/views/Home');
    route('/admin/orders.html@admin/views/OrdersList.js', 'orders@getAll');

    route('/admin/users/list.html@admin/views/UsersList', 'users@getAll', [

            route('/admin/users/:id/update.html@admin/views/UsersUpdate', 'users@getSingle'),
            route('/admin/users/add.html@admin/views/UsersAdd'),
            route('/admin/users/:id/summary.html@admin/views/UsersSummary', 'users@getSingle')
        ]
    );
});

ReactDOM.render(<App />, document.getElementById('root'));

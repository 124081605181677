import {useContext} from 'react';
import AppContext from 'library/AppContext';

function useAuthentication(){

    const context = useContext(AppContext);

    function login(access_token, refresh_token, groups){

        return context.authentication.login(access_token, refresh_token, groups);
    }

    function logout(){

        return context.authentication.logout();
    }

    return [login, logout];
}

export default useAuthentication;

import React from 'react';
import styled from 'styled-components';

const Container = styled.div(props => `

    background: ${props.theme.backgroundColor.grays.white};
    padding-top: ${props.theme.padding.bigger};
    padding-right: ${props.theme.padding.bigger};
    padding-bottom: ${props.paddingBottom === undefined ? props.theme.padding.bigger : props.paddingBottom};
    padding-left: ${props.theme.padding.bigger};
    border-radius: 0  0 ${props.theme.borderRadius.normal} ${props.theme.borderRadius.normal};
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: ${props.theme.borderColor.main.normal};
    ${props.marginBottom !== undefined ? `margin-bottom: ${props.marginBottom};` : ``}
`);

function Content(props){

    return (

        <Container {...props.styled}>{props.children}</Container>
    );
}

export default Content;

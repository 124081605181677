import React, {useState} from 'react';
import styled from 'styled-components';

const Label = styled.div(props => `

    padding-bottom: ${props.theme.padding.normal};
    ${props.error !== false && `color: ${props.theme.color.negative.normal}`}
`);

const Container = styled.div(props => `

    display: flex;
    height: ${props.theme.height.normal};
    margin-bottom: ${props.theme.margin.normal};
`);

const Selected = styled.div(props => `

    display: flex;
    align-items: center;
    border-top: 1px solid ${props.theme.borderColor.main.normal};
    border-bottom: 1px solid ${props.theme.borderColor.main.normal};
    border-left: 1px solid ${props.theme.borderColor.main.normal};
    padding: 0 ${props.theme.padding.smaller} 0 ${props.theme.padding.smaller};
    background: #fff;
    border-radius: ${props.theme.borderRadius.smaller} 0 0 ${props.theme.borderRadius.smaller};
`);

const Text = styled.span(props => `

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: ${props.width};
    height: ${props.theme.height.normal};
    line-height: ${props.theme.height.normal};
    ${props.grayed === true && `color: ${props.theme.color.grays.gray}`}
`);

const Input = styled.input(() => `

    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`);

const Button = styled.label(props => `

    display: flex;
    align-items: center;
    cursor: pointer;
    background: linear-gradient(${props.theme.backgroundColor.sub.light}, ${props.theme.backgroundColor.sub.normal});
    border: 1px solid ${props.theme.backgroundColor.sub.normal};
    color: ${props.theme.color.sub.normal};
    padding: 0 ${props.theme.padding.smaller} 0 ${props.theme.padding.smaller};
    white-space: nowrap;
    border-radius: 0 ${props.theme.borderRadius.smaller} ${props.theme.borderRadius.smaller} 0;

    &:hover {
    
        background: linear-gradient(${props.theme.backgroundColor.positive.light}, ${props.theme.backgroundColor.positive.normal});
        border: 1px solid ${props.theme.backgroundColor.positive.normal};
    }
`);

const Icon = styled.i(props => `

    padding-right: ${props.theme.padding.smaller};
`);

export default function File(props){

    const [state, setState] = useState({name: '', size: 0});

    if(props.name === undefined){

        throw new Error('Name is not set');
    }

    if(props.label === undefined){

        throw new Error('Label is not set');
    }

    if(props.tooltip === undefined){

        throw new Error('Tooltip is not set');
    }

    if(props.placeholder === undefined){

        throw new Error('Placeholder is not set');
    }

    function handle(e){

        if(e.target.files.length === 0){

            props.onChange({name: props.name, value: undefined});

            setState({
                
                name: '',
                size: 0
            });
        }

        else {

            props.onChange({name: props.name, value: e.target.files[0]});

            setState({
                
                name: e.target.files[0].name,
                size: e.target.files[0].size
            });
        }
    }

    return (

        <React.Fragment>

            <Label error={props.error}>{props.label}:</Label>

            <Container>

                <Selected>

                    <Text width={props.styled.width} grayed={state.name === '' ? true : false}>{state.name === '' ? props.placeholder : state.name}</Text>

                </Selected>

                <Input type="file" name={props.name} id={props.name} onChange={handle} />

                <Button htmlFor={props.name}>

                    <Icon className="fa fa-upload" aria-hidden="true" />
                    {props.tooltip}

                </Button>

            </Container>

        </React.Fragment>
    );
}

File.defaultProps = {

    error: false,

    styled: {

        width: '10em'
    }
};
